@import 'assets/stylesheet/variables.scss';

.epub-content {
  overflow: hidden !important;
}

// .epubfile{
//   width:100% !important;
//   height: 100% !important;
// }

.play-exit-btns{
  display: flex;

}

.d-true{
  visibility: visible;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.modal-content {

  // position: absolute;
  background-color: white;
  border-radius: $theme-border-radius;
  padding: 20px 70px;
  max-height: 80%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // left: 20%;
  width:60%;
  // right:20%;
  // top:10%;

}

.close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.navigation-icons {
  // display: flex;
  // justify-content: space-between;
  width: 100%;
  margin-top: auto;
  padding: 20px 0;
  display:absolute;
  top:50%;
}

.left-icon,
.right-icon {
  cursor: pointer;
  font-size: 30px;
  color: #9134d8;
  width: 10%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.rectangle-container {
  width: 100%;
  display: flex;
  justify-content: center;

}

.rectangle {
  border: 2px solid #f1eef5;
 justify-content: center;
 text-align: center;
  // border-radius: 21.86px;
  border-radius: $theme-border-radius;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.modal-img {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.modal-text-container {
  padding: 36px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-text {
  font-family: "Rubik", sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
}

.modal-buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.record-button,
.end-button,
.try-again-button {
  margin: 0 10px;
  width: 271px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #9134d8;
  border: #7a14c7 solid 2px;
  border-radius: $theme-border-radius;
  color: white;
  position: absolute;
  bottom: 10px;
}

.left-nav {
  position: absolute;
  left: 10%;
  top:50%;
  font-size: 70px;

}

.right-nav {
  position: absolute;
  right: 10%;
  top:50%;
  font-size: 70px;
}

@media screen and (max-width: 500px) {
  .play-exit-btns{
    flex-direction: column;
  }

  .finishbtn{
    margin-top: 4px !important;
  }
  .modal-content {
    padding: 40px; /* Adjust padding for smaller screens */
    // left:10%;
    // right:10%;
    // width:80%;
    max-height: 70%;
    border-color: transparent;/* Adjust max-height for smaller screens */
  }


  .modal-text {
    font-size: 18px; /* Adjust font size for smaller screens */
    line-height: 26px;
    padding-left: 10px;/* Adjust line height for smaller screens */
  }

  .start-button,
  .try-again-button {
    width: 100%; /* Make buttons full width on smaller screens */
  }
  .image-container {
    padding: 10px;
  }
  .epubfile{
    margin-top: 60%;
    width: 100%;
  }
  .left-nav {
    position: absolute;
    left: 1%;
    top:50%;
    font-size: 30px;

  }

  .right-nav {
    position: absolute;
    right: 1%;
    top:50%;
    font-size: 30px;
  }
  .passage-audio{
    position: absolute;
  left:15% !important;
  top: 76% !important;
  width:50%;
  }


  .playback-rate{
    margin-top: 2px !important;
  }
  .btn,.finishbtn{
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
}

@media screen and (min-width:500) and (max-width: 768px) {
  .modal-content {
    padding: 40px; /* Adjust padding for smaller screens */
    // left:10%;
    // right:10%;
    width:80%;
    max-height: 70%;
    border-color: transparent;/* Adjust max-height for smaller screens */
  }


  .modal-text {
    font-size: 18px; /* Adjust font size for smaller screens */
    line-height: 26px;
    padding-left: 10px;/* Adjust line height for smaller screens */
  }

  .start-button,
  .try-again-button {
    width: 100%; /* Make buttons full width on smaller screens */
  }
  .image-container {
    padding: 10px;
  }
  .epubfile{
    margin-top: 60%;

  }
  .left-nav {
    position: absolute;
    left: 1%;
    top:50%;
    font-size: 30px;

  }

  .right-nav {
    position: absolute;
    right: 1%;
    top:50%;
    font-size: 30px;
  }
  .passage-audio{
    position: absolute;
  left:30% !important;
  top: 76% !important;
  width:50%;
  }


  .playback-rate{
    margin-top: 2px !important;
  }
  .btn,.finishbtn{
    padding-left: 25px !important;
    padding-right: 25px !important;
  }

}



// .playback-rate {
//   position: absolute;
//   padding-top: 49%;
//   padding-left: 45%;
//  }
// .playback-rate label {
//   font-weight: bold;
// }

.passage-audio {
  position: absolute;
  left:25%;
  top: 85%;
  width:50%;

}

.audio-player {
  padding-top: 50%;
  padding-left: 45%;
}

.playback-rate{
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.playback-label{

}

.btn,.finishbtn{
  margin: 0 10px;
  width: 271px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #9134d8;
  border: #7a14c7 solid 2px;
  border-radius: $theme-border-radius;
  color: white;




}

@media screen  and (min-width:850px) {
  .text-center{
    display: flex;
    justify-content: center;
  }

  .playback-label{
    // padding-top: 10px;

  }
  .playback-rate{
    padding-top: 10px;
  }


}


.read_aloud_container{
  width: 100vw;
  display: flex;
  justify-content: space-between;
  gap:8px;
  align-items: center;
  height: 100vh;
}

.modal-content{
  width: 70% !important;
  min-width: 500px;
}

#epub-file{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .epub-container{
   width: fit-content !important;
  min-width: 500px !important;
  max-width: 90% !important;
  overflow: auto !important;

  @media (max-width:480px) {
    min-width: 200px !important;
    max-width: 100% !important;
  }
  }
}


@media (max-width:700px) {
  .epub-container{
    min-width: 0 !important;
  }
  .modal-content{
    min-width: 0 !important;
    padding: 0px !important;
    margin: 0 !important;
  }
}