@import "assets/stylesheet/variables.scss";

.storycard-container {
  transition: ease-in-out 0.4s;
  // width: 232px;
  height: 276px;
  @media screen and (max-width: 768px) {
    //width: 155px;
    height: 224px;
    //FREAD-1010
    margin: unset;
    // margin-left: 7%;
    .story-data {
      padding: 10px 8px;
    }
    .story-type {
      left: 1px !important;
    }
    .bookmark-icon {
      right: 1px !important;
    }

  }
  // background-image: linear-gradient(359deg, #552d83, #432870 64%, #361e5f);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: $theme-border-radius;
  margin: 20px 20px 0 20px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  .story-card {
    width: 232px;
    margin-right: 20px;
  }
  .story_right_icons{
    position:absolute;
    right: 14px;
    top: 10px;
    display: flex;
    // flex-direction: column;
    align-items: center;
    gap: 5px;
  }

  .story_card_read_aloud_icon{
  //   position: absolute;
  // right: 16px;
  // top: 35px;
  background: white;
  /* padding: 5px; */
  height: 23px;
  width: 23px;
  padding: 2px;
  border-radius: 100%;
  color: #a5a5a5;
  }
  .bookmark-icon {
    // position: absolute;
    border-radius: 14px;
    background-color: #ffffff;
    color: $theme-color;
    right: 14px;
    top: 10px;
    display: flex;
    // width: 52px;
    height: 23px;
  }
  .read-time {
    position: absolute;
    border-radius: 14px;
    background-color: #ffffff;
    color: $theme-color;
    right: 14px;
    top: 10px;
    font-size: 12px;
    padding: 2.5px 6px 2px 6px;
    display: flex;
    // width: 52px;
    height: 20px;
  }
  .story-type {
    position: absolute;
    border-radius: 14px;
    background-color: #ffffff;
    color: $theme-color;
    left: 10px;
    top: 10px;
    font-size: 12px;
    padding: 2.5px 6px 2px 6px;
    display: flex;
    align-items: center;
    // width: 52px;
    height: 20px;
  }
  .bottom-div {
    justify-content: space-between;
    display: flex;
    .title {
      display: flex;
      flex-direction: column;
      font-size: 18px;
      font-weight: 500;
      color: #fff;
      display: flex;
      align-items: flex-start;
      max-height: 85px;
      @media screen and (max-width: 768px) {
        width: 85%;
      }

      .story-name {
        flex: 0.9;
        // padding-right: 6px;
        // line-height: 1.1;
        // margin-right: 40px;
        line-height: 1.1em;
        height: 2.2em;
        overflow: hidden;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      // .bookmark-icon {
      //   flex: 0.1;
      //   margin-top: 6px;
      // }
    }
  }
  .story-author,
  .story-publisher {
    color: #fff;
    font-size: 12px;
    opacity: 0.8;
    line-height: 1.1em;
    height: 2.2em;
    overflow: hidden;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
    padding-left: 0px;
    li {
      border-radius: $theme-border-radius;
    }
  }
  .story-data {
    background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.65) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    padding: 20px 16px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    .story-description {
      color: rgba($color: #ffffff, $alpha: 0.8);
    }
  }
}

.storycard-container:hover{
  transform: translateY(-8px);
  box-shadow: 0px 6px 10px rgb(207, 202, 202);
}